import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/plugins/vant'   //按需引入vant
import 'vant/lib/index.less';  // vant样式
import '../src/utils/global'
// import api from '../src/http/api' //引入相关的api请求接口
import Meta from "vue-meta";
import '../src/style/index.css' //页面样式
import '../src/style/reset.css' //重置样式以及修改vant的默认样式
import '../src/utils/fontsizerem.js' //页面自适应js
import { Dialog } from 'vant';

// 全局注册
Vue.use(Dialog);

// 组件实例的原型的原型指向的是Vue.prototype
//任意组件可以使用API相关的接口
Vue.use(Meta);
// Vue.prototype.$api = api
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
